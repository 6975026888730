
ol.lst-kix_list_1-3 {
  list-style-type: none;
}
ol.lst-kix_list_1-4 {
  list-style-type: none;
}
.lst-kix_list_2-6 > li:before {
  content: '\0025cf   ';
}
.lst-kix_list_2-7 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_3-2 {
  list-style-type: none;
}
.lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}
ol.lst-kix_list_1-5 {
  list-style-type: none;
}
ol.lst-kix_list_3-3 {
  list-style-type: none;
}
ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}
ol.lst-kix_list_1-6 {
  list-style-type: none;
}
ol.lst-kix_list_3-4 {
  list-style-type: none;
}
ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_2-5 > li:before {
  content: '\0025aa   ';
}
.lst-kix_list_2-8 > li:before {
  content: '\0025aa   ';
}
ol.lst-kix_list_1-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-2 {
  list-style-type: none;
}
ol.lst-kix_list_3-0 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
.lst-kix_list_3-0 > li:before {
  content: '' counter(lst-ctn-kix_list_3-0, decimal) '. ';
}
.lst-kix_list_3-1 > li:before {
  content: '\0025cf   ';
}
.lst-kix_list_3-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_3-2, lower-latin) ') ';
}
ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}
ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}
ul.lst-kix_list_3-1 {
  list-style-type: none;
}
.lst-kix_list_3-5 > li:before {
  content: '' counter(lst-ctn-kix_list_3-5, lower-latin) '. ';
}
.lst-kix_list_3-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_3-4, decimal) ') ';
}
ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}
ol.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_3-3, lower-roman) ') ';
}
ol.lst-kix_list_3-5 {
  list-style-type: none;
}
.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}
ol.lst-kix_list_1-8 {
  list-style-type: none;
}
ol.lst-kix_list_3-6 {
  list-style-type: none;
}
ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}
ol.lst-kix_list_3-7 {
  list-style-type: none;
}
ol.lst-kix_list_3-8 {
  list-style-type: none;
}
.lst-kix_list_3-8 > li:before {
  content: '' counter(lst-ctn-kix_list_3-8, lower-latin) ') ';
}
li.li-bullet-1:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 36pt;
}
.lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}
.lst-kix_list_3-6 > li:before {
  content: '' counter(lst-ctn-kix_list_3-6, lower-roman) '. ';
}
.lst-kix_list_3-7 > li:before {
  content: '' counter(lst-ctn-kix_list_3-7, decimal) ') ';
}
ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}
.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}
.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}
ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}
ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}
.lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}
ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
.lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}
.lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}
.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}
ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}
ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}
ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}
ul.lst-kix_list_2-8 {
  list-style-type: none;
}
ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
}
ul.lst-kix_list_2-3 {
  list-style-type: none;
}
ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}
ul.lst-kix_list_2-0 {
  list-style-type: none;
}
ul.lst-kix_list_2-1 {
  list-style-type: none;
}
ul.lst-kix_list_2-6 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.'
    counter(lst-ctn-kix_list_1-1, decimal) ' ';
}
.lst-kix_list_1-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-2, lower-latin) ') ';
}
ul.lst-kix_list_2-7 {
  list-style-type: none;
}
ul.lst-kix_list_2-4 {
  list-style-type: none;
}
ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-3, lower-roman) ') ';
}
.lst-kix_list_1-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-4, decimal) ') ';
}
ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}
.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}
.lst-kix_list_1-7 > li:before {
  content: '' counter(lst-ctn-kix_list_1-7, decimal) ') ';
}
.lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}
.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}
.lst-kix_list_1-5 > li:before {
  content: '' counter(lst-ctn-kix_list_1-5, lower-latin) '. ';
}
.lst-kix_list_1-6 > li:before {
  content: '' counter(lst-ctn-kix_list_1-6, lower-roman) '. ';
}
.lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_2-0 > li:before {
  content: '\0025cf   ';
}
.lst-kix_list_2-1 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}
.lst-kix_list_1-8 > li:before {
  content: '' counter(lst-ctn-kix_list_1-8, lower-latin) ') ';
}
.lst-kix_list_2-2 > li:before {
  content: '\0025aa   ';
}
.lst-kix_list_2-3 > li:before {
  content: '\0025cf   ';
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c34 {
  border-right-style: solid;
  padding: 0pt 9pt 17.5pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 74.3pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c19 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 260.9pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c27 {
  border-right-style: solid;
  padding: 0pt 5.4pt 0pt 5.4pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 206.7pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c28 {
  border-right-style: solid;
  padding: 0pt 9pt 17.5pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 461.4pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.c30 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  text-indent: -5.2pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 5.2pt;
}
.c5 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 36pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c6 {
  margin-left: 54pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Sora';
  font-style: normal;
}
.c0 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Sora';
  font-style: normal;
}
.c13 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 8pt;
  font-family: 'Arial';
  font-style: normal;
}
.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Sora';
  font-style: normal;
  line-height: 18px;
}
.c29 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c33 {
  padding-top: 0pt;
  padding-bottom: 10pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c3 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c31 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c21 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c14 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c7 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c23 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: 'Arial';
  font-style: normal;
}
.c24 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: 'Times New Roman';
  font-style: normal;
}
.c17 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.c25 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: left;
}
.c26 {
  margin-left: auto;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.c4 {
  font-size: 11pt;
  font-style: italic;
  color: #000000;
  font-weight: 700;
}
.c35 {
  font-weight: 400;
  vertical-align: baseline;
  font-family: 'Sora';
  font-style: normal;
}
.c9 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}
.c32 {
  background-color: #ffffff;
  /* max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt; */
}
.c11 {
  margin-left: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
}
.c10 {
  font-weight: 700;
  font-style: italic;
}
.c37 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.c12 {
  margin-left: 18pt;
  padding-left: 18pt;
}
.c15 {
  padding: 0;
  margin: 0;
}
.c20 {
  color: #000000;
}
.c18 {
  height: 10pt;
}
.c16 {
  height: 0pt;
}
.c22 {
  font-weight: 700;
}
.c8 {
  font-size: 11pt;
  line-height: 18px;
}
.c36 {
  font-weight: 400;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: 'Sora';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 24pt;
  font-family: 'Sora';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
li {
  color: #000000;
  font-size: 10pt;
  font-family: 'Sora';
  line-height: 18px;
}
p {
  margin: 0;
  font-size: 10pt;
  font-family: 'Sora';
  line-height: 18px;
}
h1 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 12pt;
  font-family: 'Sora';
  line-height: 18px;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 0pt;
  color: #000000;
  font-size: 11pt;
  padding-bottom: 12pt;
  font-family: 'Sora';
  line-height: 18px;
}
/* h3 {
  padding-top: 0pt;
  color: #000000;
  font-size: 11pt;
  padding-bottom: 12pt;
  font-family: 'Sora';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 0pt;
  color: #000000;
  font-size: 10pt;
  padding-bottom: 12pt;
  font-family: 'Sora';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 0pt;
  color: #000000;
  font-size: 10pt;
  padding-bottom: 12pt;
  font-family: 'Sora';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 0pt;
  color: #000000;
  font-size: 10pt;
  padding-bottom: 12pt;
  font-family: 'Sora';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
} */
