.breakdown-flex {
  width: 50%;
}
.breakdown-left {
  margin-right: 15px;
}

.breakdown-left-shrink {
  animation: 1s breakdown-left-shrink ease forwards;
}
@keyframes breakdown-left-shrink {
  0% {
    opacity: 1;
    margin-right: 15px;
  }
  30% {
    opacity: 0;
    width: 50%;
    margin-right: 0;
  }
  100% {
    opacity: 0;
    width: 0;
    margin-right: 0;
  }
}
.breakdown-left-expand {
  animation: 1s breakdown-left-expand ease forwards;
}
@keyframes breakdown-left-expand {
  0% {
    opacity: 0;
    width: 0;
    margin-right: 0;
  }
  70% {
    opacity: 0;
    width: 50%;
    margin-right: 15px;
  }
  100% {
    opacity: 1;
    width: 50%;
    margin-right: 15px;
  }
}

.breakdown-right-expand {
  animation: 1s breakdown-right-expand ease forwards;
}
@keyframes breakdown-right-expand {
  30% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}

.breakdown-right-shrink {
  animation: 1s breakdown-right-shrink ease forwards;
}
@keyframes breakdown-right-shrink {
  0% {
    width: 100%;
  }
  70% {
    width: 50%;
  }
}

.breakdown-right-expand .total-breakdown {
  animation:
    0s total-breakdown-hidden ease forwards 1s,
    0.7s total-breakdown-opacity ease forwards 0.3s;
}
@keyframes total-breakdown-hidden {
  0% {
    display: block;
  }
  100% {
    display: none;
  }
}
@keyframes total-breakdown-opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.hide-platform-breakdown {
  height: 0;
  overflow: hidden;
}
.show-platform-breakdown {
  height: 0;
  overflow: hidden;
  animation:
    0s platform-breakdown-show ease forwards 0.7s,
    0.3s platform-breakdown-opacity ease forwards 0.7s;
}
@keyframes platform-breakdown-show {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@keyframes platform-breakdown-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .breakdown-flex {
    width: 100%;
    min-width: 100%;
  }
  .breakdown-left {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .breakdown-wrapper {
    flex-wrap: wrap;
  }
}
