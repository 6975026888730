body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html {
  scroll-behavior: smooth;
}
.iframe {
  border-width: 0px !important;
}
.panel-iframe {
  border-width: 0px !important;
}
.error-panel-iframe {
  border-width: 0px !important;
  margin: 10px 0px 20px 0px;
}
.inner-header > header {
  background-color: #fff;
  box-shadow: none;
  width: 90%;
}
.header-link {
  padding: 0 10px;
  text-decoration: none;
  font-size: 20px;
  color: #223354;
  font-weight: 450;
}
.header-link:hover {
  color: #f04b06;
}
.active {
  color: #f04b06;
}

.subheader-link {
  padding: 0 10px;
  text-decoration: none;
  font-size: 15px;
  color: #c7d4ed;
  font-weight: 450;
  margin-left: 15px;
}
.subheader-link:hover {
  color: #ffffff;
}
.subheader-link.active {
  background-color: #122937;
  color: #ffffff;
  font-weight: bold;
}

.css-1qgozbj-MuiDataGrid-root {
  margin-top: 10px;
  background-color: #fff;
}
.add-button {
  background-color: #fcfcfc !important;
  margin-left: 10px !important;
}
.add-button > .MuiSvgIcon-fontSizeInherit {
  color: #000;
}
.credential-form {
  padding: 50px;
  background-color: #fff;
}
.credential-form > .MuiFormControl-root {
  margin: 10px 0px;
}
.plateform-grid {
  width: 100%;
  height: 150px;
  background-color: #fff;
}
.plateform-img > img {
  width: 80%;
  margin-left: 17px;
  margin-right: auto;
  margin-top: 14px;
}
.grubhub-img {
  margin-top: 37px !important;
}
.float-right {
  float: right;
}
.plateform-credentials-grid {
  width: 100%;
  height: 400px;
  background-color: #fff;
}
.clear-both {
  clear: both;
}
.plateform-credentials-content {
  padding: 10px;
}
.hide-app > .intercom-lightweight-app {
  display: none !important;
}
.css-vsdrqo-MuiPaper-root-MuiDrawer-paper {
  background-color: #122937 !important;
}
.css-nlrp5c-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-nlrp5c-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #000 !important;
  border: none;
}
.custom-green-button {
  background-color: #196e82 !important;
  color: white !important;
  border-radius: 0px;
}
.MuiDataGrid-columnHeaders {
  background-color: #f2f2f2;
}
.green-success {
  color: #84e1cc;
  font-size: 16px !important;
}
.MuiTabs-indicator {
  border: none !important;
  box-shadow: none !important;
  border-bottom: #196e82 2px solid !important;
  border-radius: 0px !important;
}
.hide-drawer > #root > .MuiBox-root > .MuiDrawer-docked {
  display: none;
}
.color-red {
  color: red;
}
.lkjDVC {
  min-width: 100% !important;
  border: 2px dashed rgb(15, 70, 90) !important;
  background-color: rgb(201, 218, 222) !important;
  color: #09234b !important;
}
.dmajdp > span {
  color: #09234b !important;
}
.csv-blocks-item {
  padding-top: 6px !important;
}
.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1500 !important;
}
@media screen and (max-width: 480px) {
  .header-link {
    font-size: 10px;
  }
  .mobile-link {
    font-size: 18px;
    padding: 2px 14px;
  }
}
.pac-container {
  z-index: 1200 !important;
}

@keyframes inProgressAnimate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.display-hubspot {
  display: none !important;
}
